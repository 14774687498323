import React, { Component } from "react";
import validator from "validator";
import { Img } from "react-image";
import { Skeleton } from "baseui/skeleton";
import { I18n } from "react-redux-i18n";
import http from "../../config/http";
import { toaster, ToasterContainer, PLACEMENT } from "baseui/toast";
import { Button, SIZE } from "baseui/button";
import { Block } from "baseui/block";
import ReCAPTCHA from "react-google-recaptcha";

import { Helmet } from "react-helmet";

const captcha_ref = React.createRef();
class CmpFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: {},
      comment: [],
      loading: false,
      form_disabled: false,
      form_loading: false,
      input: {
        blogId: "",
        name: "",
        email: "",
        message: "",
        token: "",
      },
      error: {
        email: "",
        name: "",
        message: "",
        token: "",
        error_email: "",
        error_name: "",
        error_message: "",
        error_token: "",
      },
    };
    this.form = this.form.bind(this);
  }
  

  componentDidMount() {
    this.getBlog(window.location.pathname);
  }

  getBlog = (key) => {
    let data = {
      url: "blog_detail",
      body: {
        query: true,
        param: key,
      },
      token: false,
    };
    this.setState(
      {
        form_loading: true,
        loading: true,
      },
      () => {
        http.APIget(data).then((res) => {
          if (res.results.status) {
            if (Object.keys(res.results.data).length !== 0) {
              this.setState(
                {
                  blog: res.results.data,
                  comment: res.results.data.comment,
                  loading: false,
                  form_loading: false,
                },
                () => {
                  this.setState(({ input }) => ({
                    input: {
                      ...input,
                      blogId: res.results.data.slug,
                    },
                  }));
                }
              );
            } else {
              this.setState(
                {
                  blog: {},
                  comment: [],
                  loading: false,
                  form_loading: false,
                },
                () => {}
              );
            }
          } else {
            console.log(res.results.error_message);
          }
        });
      }
    );
  };

  emptyKey = (name, value) => {
    let message = "";
    switch (name) {
      case "email":
        message = !validator.isEmpty(value) ? "" : "Harus diisi";
        if (!message)
          message = validator.isEmail(value) ? "" : "Format email tidak valid";
        break;
      case "password":
        message = !validator.isEmpty(value) ? "" : "Harus diisi";
        break;
      default:
        break;
    }

    this.setState(({ error }) => ({
      error: {
        ...error,
        [name]: message,
      },
    }));
  };

  keyTemp = (token) => {
    this.setState(({ input }) => ({
      input: {
        ...input,
        "token": token,
      },
    }));
  }

  fieldType = (event) => {
    const { name, value } = event.target;
    this.emptyKey(name, value);
    if (
      Object.keys(this.state.input).length === 0 &&
      this.state.input.constructor === Object
    ) {
      this.setState((prevState) => ({
        form_disabled: true,
      }));
    } else if (
      this.state.input.message === "" ||
      this.state.input.email === ""
    ) {
      this.setState((prevState) => ({
        form_disabled: true,
      }));
    } else {
      this.setState((prevState) => ({
        form_disabled: false,
      }));
    }
    this.setState(({ input }) => ({
      input: {
        ...input,
        [name]: value,
      },
    }));
  };

  form = (e) => {
    e.preventDefault();
    const captcha_value = captcha_ref.current.getValue();
    let params = {
      url: "comment",
      token: false,
      body: {
        email: this.state.input.email,
        name: this.state.input.name,
        message: this.state.input.message,
        token: this.state.input.token,
        blogId: this.state.input.blogId,
      },
    };

    this.setState(
      {
        form_loading: true,
        form_disabled: true,
      },
      () => {
        http
          .APIparam(params)
          .then((res) => {
            if (res.results.status) {
              this.setState(
                {
                  form_loading: false,
                  form_disabled: false,
                },
                () => {
                  this.setState(({ input }) => ({
                    input: {
                      ...input,
                      name: "",
                      blogId: "",
                      token: "",
                      email: "",
                      message: "",
                    },
                  }));
                  captcha_ref.current.reset();
                  let toastKey;
                  const msg = I18n.t("blog.commentTerm");
                  const ok = (
                    <Block
                      marginTop="15px"
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        size={SIZE.compact}
                        onClick={() => toaster.clear(toastKey)}
                      >
                        Ok
                      </Button>
                    </Block>
                  );
                  toastKey = toaster.info(
                    <>
                      {msg}
                    </>,
                    {
                      onClose: () => console.log("Toast closed."),
                      overrides: {
                        InnerContainer: {
                          style: { width: "100%" },
                        },
                      },
                    }
                  );
                  this.getBlog(window.location.pathname);
                  this.messagesEnd.scrollIntoView({ behavior: "smooth" });
                }
              );
            } else {
              res.results.error_message.forEach((element) => {
                console.log(element);
                if (element.error_field === "email") {
                  this.setState(({ error }) => ({
                    error: {
                      ...error,
                      email: element.error_message,
                    },
                  }));
                }
                if (element.error_field === "name") {
                  this.setState(({ error }) => ({
                    error: {
                      ...error,
                      name: element.error_message,
                    },
                  }));
                }
                if (element.error_field === "message") {
                  this.setState(({ error }) => ({
                    error: {
                      ...error,
                      message: element.error_message,
                    },
                  }));
                }
                if (element.error_field === "token") {
                  this.setState(({ error }) => ({
                    error: {
                      ...error,
                      token: element.error_message,
                    },
                  }));
                }
              });
              this.setState((prevState) => ({
                form_loading: false,
                form_disabled: false,
              }));
              captcha_ref.current.reset();
            }
          })
          .catch((err) => {
            console.log(err);
            captcha_ref.current.reset();
          });
      }
    );
  };

  loaderImage = (key, alt) => {
    return (
      <Img
        src={[key]}
        loader={
          <Skeleton
            rows={1}
            width="100%"
            animation
            overrides={{
              Row: {
                style: {
                  height: "480px",
                  borderRadius: "20px",
                },
              },
            }}
          />
        }
        alt={alt}
      />
    );
  };
  
  render() {
    return (
      <>
        {this.state.loading === true ? (
          <>
            <div className="page-banner mt--80">
              <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-lg-12">
                    <div className="banner-content"></div>
                  </div>
                </div>
              </div>
            </div>
            <section className="pb-md-120 pb--10 pt--20">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 mb--40 mb-md--0">
                    <div className="blog-post-details">
                      <div className="post-image mb--40">
                        <Skeleton
                          rows={1}
                          width="100%"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                height: "480px",
                                borderRadius: "20px",
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="text-block-1 mb--120">
                        <Skeleton
                          rows={2}
                          width="140px"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                borderRadius: "10px",
                                height: "20px",
                                marginBottom: "40px",
                              },
                            },
                          }}
                        />
                        <Skeleton
                          rows={2}
                          width="100%"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                borderRadius: "10px",
                                height: "20px",
                                marginBottom: "35px",
                              },
                            },
                          }}
                        />

                        <Skeleton
                          rows={2}
                          width="100%"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                borderRadius: "10px",
                                height: "20px",
                                marginBottom: "20px",
                              },
                            },
                          }}
                        />
                        <Skeleton
                          rows={4}
                          width="100%"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                borderRadius: "10px",
                                height: "20px",
                                marginBottom: "20px",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Blog | Ikanesia - {`${this.state.blog.title}`}</title>
              <link rel="canonical" href="https://ikanesia.com/"/>
              <meta name="keywords" content="Jual beli, ikane hias, marketplace ikanhias"></meta>
              <meta
                name="description"
                content="Ikanesia hadir dalam memenuhi kebutuhan para pecinta ikan dalam bertransaksi Jual beli dan juga lelang Ikan hias."
              />
            </Helmet>
            <div className="page-banner mt--80">
              <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-lg-12">
                    <div className="banner-content">
                      <h2 className="title">{this.state.blog.title}</h2>
                      <p>
                        <a href="">{this.state.blog.created_date}</a>
                        <span className="bullet"></span>
                        <a href="">{this.state.blog.created_by}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="pb-md-120 pb--10 pt--20">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8 mb--20 mb-md--0">
                    <div className="blog-post-details">
                      <div className="post-image mb--20">
                        {this.loaderImage(
                          this.state.blog.image,
                          this.state.blog.title
                        )}
                      </div>
                      <div
                        className="text-block-1 mb--20"
                        dangerouslySetInnerHTML={{
                          __html: this.state.blog.content,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="reply-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="reply-form pb--40 pb-lg--75">
                      <h3 className="reply-block-title pb--35">
                        {I18n.t("blog.commentTitle")}
                      </h3>
                      <form noValidate autoComplete="off">
                        <input
                          type="text"
                          onChange={this.fieldType}
                          name="name"
                          className={
                            !!this.state.error.name
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder={I18n.t("blog.commentName")}
                        />
                        <input
                          type="email"
                          name="email"
                          onChange={this.fieldType}
                          className={
                            !!this.state.error.email
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder={I18n.t("blog.commentEmail")}
                        />
                        <textarea
                          name="message"
                          onChange={this.fieldType}
                          className={
                            !!this.state.error.message
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                          placeholder={I18n.t("blog.commentMessage")}
                        ></textarea>

                        <ReCAPTCHA
                          ref={captcha_ref}
                          size="normal"
                          onChange={this.keyTemp}
                          sitekey="6LcI0-gUAAAAAEX0KBsv9wYqHd58xZp1uMl-4NzG"
                        />

                        <div className="invalid-feedback mt--20">
                          {!!this.state.error.token
                            ? this.state.error.token + "!!"
                            : ""}
                        </div>

                        <button
                          disabled={this.state.form_disabled}
                          color="primary"
                          type="button"
                          onClick={this.form}
                          className="btn btn-ikanesia mt--70"
                        >
                          {I18n.t("blog.commentSubmit")}
                        </button>
                      </form>
                    </div>
                    <div
                      className="comments"
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    >
                      <h3 className="reply-block-title pb--35">
                        {I18n.t("blog.commentHeader")}
                      </h3>
                      {this.state.form_loading === false ? (
                        this.state.comment.length === 0 ? (
                          <div className="single-comment text-center">
                            <div className="user-small-card  justify-content-center text-center">
                              <div className="user-img">
                                <img
                                  src={require("../../image/png/comment-user-1.png")}
                                  alt=""
                                />
                              </div>
                            </div>
                            <p className="mt--20">
                              Belum Ada Komentar . . . . .
                            </p>
                          </div>
                        ) : (
                          this.state.comment.map((k, i) => {
                            return (
                              <div key={i} className="single-comment">
                                <div className="user-small-card">
                                  <div className="user-img">
                                    <img
                                      style={{ borderRadius: "50%" }}
                                      src={require("../../image/png/logo.png")}
                                      alt=""
                                    />
                                  </div>
                                  <div className="user-identity">
                                    <h3 className="name">{k.name}</h3>
                                    <p className="date">{k.created_date}</p>
                                  </div>
                                </div>
                                <p>{k.message}</p>
                              </div>
                            );
                          })
                        )
                      ) : (
                        <>
                          <div class="single-comment">
                            <div class="user-small-card">
                              <div class="user-img">
                                <Skeleton
                                  rows={1}
                                  width="70px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        borderRadius: "50%",
                                        height: "70px",
                                        marginBottom: "20px",
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <div class="user-identity">
                                <Skeleton
                                  rows={2}
                                  width="240px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        borderRadius: "10px",
                                        height: "20px",
                                        marginLeft: "10px",
                                        marginBottom: "20px",
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                            <Skeleton
                              rows={2}
                              width="100%"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    borderRadius: "10px",
                                    height: "20px",
                                    marginBottom: "20px",
                                  },
                                },
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        <ToasterContainer
          placement={PLACEMENT.bottom}
          autoHideDuration={7000}
        ></ToasterContainer>
      </>
    );
  }
}

export default CmpFeature;
