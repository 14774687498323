import React, { useState, useEffect } from "react";
import { Navigation } from "baseui/side-navigation";
import http from "../../config/http";
import { Skeleton } from "baseui/skeleton";
import { useDispatch, useSelector } from "react-redux";
export default (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [pre, setpre] = useState(false);
  const [inner, setinner] = useState(false);
  const [preFaq, setpreFaq] = useState(null);
  const lang = useSelector((state) => state.setting);
  const [activeItemId, setActiveItemId] = React.useState("#primary");

  const openFaq = (key) => {
    let data = {
      url: "faq",
      body: {
        query: true,
        language: lang.language,
        id: key,
      },
      token: false,
    };
    setpre(true);
    setinner(true);
    http.APIget(data).then((res) => {
      if (res.results.status) {
        setpre(false);
        setpreFaq(res.results.query.content);
        // setinner(false);
      } else {
        setinner(false);
        console.log(res.results.error_message);
      }
    });
  };

  const Faq = () => {
    let tree = [];
    let data = {
      url: "faq",
      body: {
        query: true,
        language: lang.language,
      },
      token: false,
    };

    http.APIget(data).then((res) => {
      if (res.results.status) {
        res.results.query.map((item, i) => {
          let treeItem = [];
          item.children.map((k, o) => {
            if (item.id === k.parent_id) {
              return treeItem.push({
                itemId: "#primary" + k.id,
                id: k.id,
                title: k.label,
              });
            }
          });
          tree.push({
            itemId: "#primary"+item.id,
            id: item.id,
            title: item.label,
            subNav: treeItem,
          });
        });
        setData(tree);
      } else {
        console.log(res.results.error_message);
      }
    });
  }

  useEffect(() => {
    Faq()
  }, []);

  useEffect(() => {
    if(props.param !== 0) {
      openFaq(props.param);
    }
  }, [props.param]);

  return (
    <>
      <div className="contact-section pt-20 pb-md--120 pb--50">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 mb--30 mb-md--0 side-bar-faq">
              <Navigation
                items={data}
                activeItemId={activeItemId}
                onChange={({ event, item }) => {
                  // prevent page reload
                  event.preventDefault();
                  setActiveItemId(item.itemId);
                  openFaq(item.id);
                }}
                overrides={{
                  NavItem: {
                    style: ({ $theme }) => {
                      return {
                        fontFamily: "CircularStd, sans-serif",
                        textTransform: "capitalize",
                        fontSize: "14px !important",
                        letterSpacing: "1px !important",
                      };
                    },
                  },
                }}
              />
            </div>
            <div className="col-lg-8 col-md-8 pt-5">
              <img
                alt=""
                className={inner ? "d-none" : "ml-5"}
                src={require("../../image/png/F.A.Q.png")}
              />
              {pre === false ? (
                <div dangerouslySetInnerHTML={{ __html: preFaq }}></div>
              ) : (
                <>
                  <div className="row p-2">
                    <div className="col-md-4 col-sm-12">
                      <Skeleton
                        rows={1}
                        animation
                        width="100%"
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-2 col-sm-12">
                      <Skeleton
                        rows={1}
                        animation
                        width="100%"
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-4 col-sm-12">
                      <Skeleton
                        rows={2}
                        animation
                        width="100%"
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Skeleton
                        rows={2}
                        animation
                        width="100%"
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-6 col-sm-12">
                      <Skeleton
                        rows={3}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col-md-12 col-sm-12">
                      <Skeleton
                        rows={3}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
