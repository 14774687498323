import * as React from "react";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import Route from "./route/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/fonts/fontawesome-5/css/all.css";
import "./style/fonts/icon-font/css/style.css";
import "./style/fonts/typography-font/typo.css";
import "./style/aos/aos.min.css";
import "./style/settings.css";
import "./style/main.css";
import "./style/custom.css";

import { connect } from "react-redux";

class AppTheme extends React.Component {
  render() {
    const engine = new Styletron();

    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <Route />
        </BaseProvider>
      </StyletronProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(AppTheme);

export default App;
