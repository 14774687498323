import React, { Component } from "react";
import styles from "../style/homepage.module.scss";

import Header from "../components/template/global/cmp_header";
import Feature from "../components/blog/cmp_feature_detail";
import Footer from "../components/template/global/cmp_footer";

// import { Helmet } from "react-helmet";

import ScrollToTop from "../components/scrollTop";

import $ from "jquery";

class PageBlogDetail extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      $("#loading").fadeOut(500);
    }, 1000);

    window.scrollTo(0, 0);

    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        $(".sticky-header").addClass("scrolling");
      } else {
        $(".sticky-header").removeClass("scrolling");
      }
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        $(".sticky-header.scrolling").addClass("reveal-header");
      } else {
        $(".sticky-header.scrolling").removeClass("reveal-header");
      }
    }
  }

  render() {
    return (
      <div>
        <div id="loading">
          <div className="load-circle">
            <span className="one">
              <img
                className="loader-logo"
                src={require("../image/png/logo.png")}
                alt=""
              />
            </span>
            <p className="loader-label">Loading .....</p>
          </div>
        </div>
        <div className="site-wrapper overflow-hidden">
          <div className="landing-1 blog-page blog-details">
            <Header style={styles} />
            <Feature style={styles} />
            <Footer style={styles} />
          </div>
          <ScrollToTop />
        </div>
      </div>
    );
  }
}

export default PageBlogDetail;
