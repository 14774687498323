import React, { Component } from "react";

import { I18n } from "react-redux-i18n";

class CmpBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="hero-area section-padding position-relative">
        <div className="hero-shape position-absolute">
          <img src={require("../../image/svg/hero-shape-svg.svg")} alt="" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-5 col-md-8 col-sm-9 order-lg-2">
              <div className="hero-img-group">
                <div
                  className="img-main"
                  data-aos="fade-down"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  <img
                    style={{ width: 380 }}
                    src={require("../../image/png/phone-illu.png")}
                    alt=""
                  />
                </div>
                <div
                  className="abs-img img-1"
                  data-aos="fade-right"
                  data-aos-duration="400"
                  data-aos-once="true"
                  data-aos-delay="300"
                >
                  <div className="image-card card-blue">
                    <div className="card-icon">
                      <i className="icon icon-check-2"></i>
                    </div>
                    <p>{I18n.t("home.bannerInner1")}</p>
                  </div>
                </div>
                <div
                  className="abs-img img-2"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <div className="image-card card-red">
                    <div className="card-icon">
                      <i className="icon icon-check-2"></i>
                    </div>
                    <p>{I18n.t("home.bannerInner2")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 order-lg-1"
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div className="hero-content py--50">
                <h1 className="title">{I18n.t("home.bannerTitle")}</h1>
                <p>{I18n.t("home.subBannerTitle")}</p>
              </div>
              <div className="cta-btns">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ikanesia.app"
                  className="google-play"
                >
                  <img
                    src={require("../../image/png/google-play.png")}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CmpBanner;
