import React, { Component } from "react";
// import styles from "../style/homepage.module.scss";

// import Header from "../components/template/global/cmp_header";
// import Banner from "../components/home/cmp_banner";
// import Feature from "../components/home/cmp_feature";
// import Footer from "../components/template/global/cmp_footer";

import http from "../config/http";

import { Helmet } from "react-helmet";
import ScrollToTop from "../components/scrollTop";
import $ from "jquery";


class PageLoaderShareURL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.getRedirectURL();
        window.scrollTo(0, 0);

        window.onscroll = function () {
            scrollFunction()
        };

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                $(".sticky-header").addClass("scrolling");
            } else {
                $(".sticky-header").removeClass("scrolling");
            }
            if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                $(".sticky-header.scrolling").addClass("reveal-header");
            } else {
                $(".sticky-header.scrolling").removeClass("reveal-header");
            }
        }
    }

    getRedirectURL = () => {
        let data = {
            url: "url_share",
            body: {
                query: true,
                short_code: window.location.pathname.replace("/", ""),
            },
            token: false,
        };
        this.setState({
            loading: true,
        });
        http.APIget(data).then((res) => {
            console.log(res);
            if (res.status) {
                this.setState({
                    loading: false
                });

                $("#loading").fadeOut(500);

                window.location.href = res.result.redirect_url;

            } else {
               console.log(res);
            }
        });
    };

    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Ikanesia | Marketplace segala jenis ikan hias</title>
                    <link rel="canonical" href="https://ikanesia.com/" />
                    <meta
                        name="description"
                        content="Ikanesia hadir dalam memenuhi kebutuhan para pecinta ikan dalam bertransaksi Jual beli dan juga lelang Ikan hias."
                    />
                </Helmet>
                <div id="loading">
                    <div className="load-circle">
                        <span className="one">
                            <img
                                className="loader-logo"
                                src={require("../image/png/logo.png")}
                                alt=""
                            />
                        </span>
                        <p className="loader-label">Loading .....</p>
                    </div>
                </div>
                <div className="site-wrapper overflow-hidden">
                    <div className="landing-1">
                       
                    </div>
                    <ScrollToTop />
                </div>
            </div>
        );
    }
}

export default PageLoaderShareURL;
