export const rupiah_to_integer = value => {
    if (value) {
        return parseInt(
            value.toString().replace(/[&\/\\#,+()$~%.'":*?<>{}\a-zA-Z\s]/g, ''),
        );
    } else {
        return 0;
    }
};

export const rupiah = value => {
    var number = parseInt(value);
    if (number) {
        var reverse = number.toString().split('').reverse().join(''),
            ribuan = reverse.match(/\d{1,3}/g);
        ribuan = ribuan.join('.').split('').reverse().join('');

        return ribuan;
    } else {
        return 0;
    }
};

export const rupiah_with_symbolic = (angka, prefix) => {
    var number_string = angka.replace(/[^,\d]/g, '').toString(),
        split = number_string.split(','),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
    return prefix == undefined ? rupiah : rupiah ? rupiah : '';
};
