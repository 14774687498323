const id = {
  home: {
    title: "Beranda",
    bannerTitle: "Marketplace Jual Beli Ikan Hias",
    subBannerTitle:
      "Aplikasi Ikanesia adalah tempat jual beli dan juga lelang ikan hias yang kini hadir di Indonesia.",
    bannerInner1: "Selamat! Kamu memenangkan lelang",
    bannerInner2: "Selamat Pagi! Ikanmu akan segera tiba sebentar lagi",
    featureTitle1: "Buy & Sell",
    featureTitle2: "Auction",
    featureTitle3: "Premium Seller",
    featureTitle4: "Pembayaran Terpercaya",
    featureInner1:
      "Telusuri berbagai macam jenis ikan dan hewan peliharaan lainnya.",
    featureInner2: "Ikuti berbagai macam lelang ikan hias dari ribuan penjual.",
    featureInner3:
      "Tingkatkan akun penjualmu dan dapatkan alat bantu dari fitur premium seller.",
    featureInner4:
      "Bebaskan rasa khawatir kamu dengan metode pembayaran dalam Ikanesia.",
    featurePreTitle: "Yuk mulai berjualan di Ikanesia.",
    featurePreInner:
      "Ikanesia hadir dalam memenuhi kebutuhan para pecinta ikan dalam bertransaksi Jual beli dan juga lelang Ikan hias. Selain itu kami juga menyediakan tempat untuk berjualan berbagai alat aksesoris dan kebutuhan berbagai macam ikan hias.",
    featurePreInner1:
      "Mudahnya terhubung antara pembeli dan penjual dengan fitur chat pribadi dan chat diskusi",
    featurePreIllustration1: "Halo, untuk ikan hias ini masih ada?",
    featurePreIllustration2:
      "Kami menyediakan berbagai macam katalog untuk anda.",
    featurePreInnerTitle1: "Berbagai Macam Katalog ",
    featurePreInnerTitle2: "Kategori Lengkap",
    featurePreInnerTitle3: "Saldo Akun",
    featurePreInnerItem2:
      "Kategori Ikanesia akan selalu update dan mengikuti perkembangan dan permintaan pasar.",
    featurePreInnerItem3:
      "Kamu akan memiliki saldo akun kamu sendiri dalam bertransaksi. Kamu bisa mencairkan dananya kapanpun kamu inginkan.",
    footerTitle: "Mengapa di Ikanesia",
    footerInner:
      "Sangat menyenangkan bila kamu menemukan ikan hias kesukaanmu. Ikanesia juga menyediakan berbagai macam event lelang ikan yang sayang untuk dilewatkan. Jangan khawatir! Kami akan memastikan keamanan transaksi pembeli dan penjual.",
    featurePreInner2:
      "Pengguna bisa menjadi Penjual dan Pembeli dalam waktu yang bersamaan tanpa harus membuat dua akun berbeda. Semua sudah disediakan oleh Ikanesia dan dapat kamu pakai dengan gratis.",
  },
  feature: {
    keyword: "Cari disini ...",
    keywordFaq: "Apa yang anda ingin tanyakan ?",
    keywordMissing: "Kata yang kamu cari tidak ditemukan ....",
  },
  blog: {
    commentTitle: "Beri komentar kamu",
    commentName: "Nama kamu",
    commentEmail: "Alamat email kamu",
    commentMessage: "Tulis komentar kamu disini . . .",
    commentSubmit: "Kirim Komentar",
    commentHeader: "Komentar",
    commentTerm: "Komentar berhasil dikirim",
  },
};

export default id;
