import axios from "axios";
import api from "./api";
import { getstorage } from "./storage";

const token = () => {
  const storeKeep = getstorage("ikanesia-agentid");
  return storeKeep ? "Bearer " + storeKeep.token : "";
};

const header = (restricted, type) => {
  if (restricted && type !== null) {
    return {
      headers: {
        "Content-Type": `${type}`,
        "x-access-token": token(), //token()
      },
    };
  } else {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
};

const APIget = async (data) => {
  let params = data.body;
  if(params.query === true) {
    const res = await axios.get(
      api[data.url],
      {
        params,
      },
      header(data.token)
    );
    return res.data;
  } else {
    const res = await axios.get(
      api[data.url],
      header(data.token)
    );
    return res.data;
  }
};

const APIparam = async (data) => {
  let params = data.body;

  if (data.type !== "" && data.type !== undefined && data.type != null) {
    // if multipart/form-data
    if (data.type === "multipart/form-data") {
      params = new FormData();
      for (var bodyKey in data.body) {
        params.append(bodyKey, data.body[bodyKey]);
      }
    }
  }

  const res = await axios.post(
    api[data.url],
    params,
    header(data.token, data.type)
  );
  return res.data;
};

export default {
  APIget,
  APIparam,
};
