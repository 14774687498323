/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from "redux";
import storage from "redux-persist/lib/storage"; // default: localStorage if web, AsyncStorage if react-native
import { persistStore, persistReducer } from "redux-persist";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";

import thunk from "redux-thunk";

import createReducer from "./reducer";
import createMiddlewares from "./middleware";
import translationsObject from "./language/index";

function configureStore() {
  // Create the store with two middlewares
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middleware = [...createMiddlewares];

  const enhancers = [applyMiddleware(...middleware, thunk)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const persistConfig = {
    key: "IK-2020",
    storage,
    whitelist: ["setting"],
  };

  const persistedReducer = persistReducer(persistConfig, createReducer);

  const store = createStore(persistedReducer, composeEnhancers(...enhancers));

  const SettingStore = localStorage.getItem("persist:IK-2020");
  if(SettingStore !== null) {
      const languageStore = JSON.parse(SettingStore);
      const languageCode = JSON.parse(languageStore.setting);

       syncTranslationWithStore(store);
       store.dispatch(loadTranslations(translationsObject));
       store.dispatch(setLocale(languageCode.language));
  } else {
     syncTranslationWithStore(store);
     store.dispatch(loadTranslations(translationsObject));
     store.dispatch(setLocale('id'));
  }

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}

// Create redux store with history
const initialState = {};
const { store, persistor } = configureStore(initialState);

export { store, persistor };
