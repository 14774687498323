import http from "../../config/http";

export const blogQuery = (query) => {
  let data = {
    url: "blog",
    body: {
      query: true,
      curpage: 1,
      cursize: 10,
      param: query,
    },
    token: false,
  };
  return {
    type: `PARAM`,
    payload: http.APIget(data).then((res) => {
      if (res.results.status) {
        console.log(res.results.data);
        return res.results.data;
      }
    }),
  };
};