import React, { Component } from "react";
// import { Link } from "react-router-dom";
import Bar from "./cmp_bar";
class CmpBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container" style={{paddingTop: 70, paddingBottom: 20}}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 pl-lg--40 order-lg-12">
              <Bar style={this.props.style} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CmpBanner;
