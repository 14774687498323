import React, { Component } from "react";
import styles from "../style/homepage.module.scss";

import Header from "../components/template/global/cmp_header";
import Feature from "../components/privacy-policy/cmp_feature";
import Footer from "../components/template/global/cmp_footer";

import { Helmet } from "react-helmet";
import ScrollToTop from "../components/scrollTop";
import $ from "jquery";

class PagePrivacy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      $("#loading").fadeOut(500);
    }, 1000);

    window.scrollTo(0, 0);

    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        $(".sticky-header").addClass("scrolling");
      } else {
        $(".sticky-header").removeClass("scrolling");
      }
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        $(".sticky-header.scrolling").addClass("reveal-header");
      } else {
        $(".sticky-header.scrolling").removeClass("reveal-header");
      }
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Privacy policy | Ikanesia - Marketplace segala jenis ikan hias
          </title>
          <link rel="canonical" href="https://ikanesia.com/" />
          <meta
            name="description"
            content="Ikanesia hadir dalam memenuhi kebutuhan para pecinta ikan dalam bertransaksi Jual beli dan juga lelang Ikan hias."
          />
        </Helmet>
        <div id="loading">
          <div className="load-circle">
            <span className="one">
              <img
                className="loader-logo"
                src={require("../image/png/logo.png")}
                alt=""
              />
            </span>
            <p className="loader-label">Loading .....</p>
          </div>
        </div>
        <div className="site-wrapper overflow-hidden">
          <div className="landing-1 terms-page">
            <Header style={styles} />
            <Feature style={styles} />
            <Footer style={styles} />
          </div>
          <ScrollToTop />
        </div>
      </div>
    );
  }
}

export default PagePrivacy;
