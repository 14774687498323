
console.log('domain now : ' + window.location.hostname);


const domain = window.location.hostname;

const build = {
  target: process.env.NODE_ENV === "production" ? "PROD" : "DEV",
  url: {
    DEV: process.env.REACT_APP_HOST || "https://api-dev.ikanesia.com",
    PROD: domain == 'dev.ikanesia.com' ? "https://api-dev.ikanesia.com" : "https://api.ikanesia.com",
  },
  url_v2: {
    DEV: process.env.REACT_APP_API_V2_HOST || "https://api-v2-dev.ikanesia.com",
    PROD: domain == 'dev.ikanesia.com' ? "https://api-v2-dev.ikanesia.com" : "https://api-v2.ikanesia.com",
  }
};

const api = {
  blog: build.url[build.target] + "/api/site/blog",
  blog_query: build.url[build.target] + "/api/site/blog-query",
  blog_category: build.url[build.target] + "/api/site/blog-category",
  sidebar_category: build.url[build.target] + "/api/site/blog-sidebar",
  comment: build.url[build.target] + "/api/blog/comment",
  blog_detail: build.url[build.target] + "/api/site/blog/detail",
  faq: build.url[build.target] + "/api/site/frequently-question",
  useFullContent: build.url[build.target] + "/api/site/usefull-content",
  url_share: `${build.url_v2[build.target]}/api/v1/url`,
  product_link: `${build.url_v2[build.target]}/api/v1/url/product-share`
};

export default api;
