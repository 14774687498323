import React, { Component } from "react";
import FlagIcon from "../../flagicon";
import { I18n } from "react-redux-i18n";

import { setlanguage } from "../../../redux/action/setting";
import { connect } from "react-redux";

import {GiHamburgerMenu} from "react-icons/gi";

import {
  Link
} from "react-router-dom";



class CmpHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      langdrop: false,
    };
    this.menu = this.menu.bind(this);
    this.changelanguage = this.changelanguage.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(this.props.language !== prevProps.language) {
      window.location.reload();
    }
  }

  changelanguage = (key) => {
    this.props.lang(key);
  };

  menu() {
    this.setState({
      popup: !this.state.popup
    })
  }
  droplangdown() {
    this.setState({
      langdrop: !this.state.langdrop,
    });
  }

  render() {
    return (
      <header className="site-header bg--conflower-blue sticky-header">
        <div className="container-fluid pr-lg--30 pl-lg--30">
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-light">
            <div className="brand-logo">
              <Link to="/">
                <img
                  className="circle-logo"
                  src={require("../../../image/png/logo.png")}
                  alt=""
                />
              </Link>
            </div>
            <div
              className={
                this.state.popup
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse"
              }
              id="mobile-menu"
            >
              <div className="navbar-nav ml-lg-auto mr--10">
                <ul className="navbar-nav main-menu">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/"
                      role="button"
                      aria-expanded="false"
                    >
                      {I18n.t("home.title")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/blog"
                      role="button"
                      aria-expanded="false"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/faq"
                      role="button"
                      aria-expanded="false"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link dropdown-toggle"
                      onClick={() => this.droplangdown()}
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.props.language === "en" ? (
                        <FlagIcon code="us" />
                      ) : (
                        <FlagIcon code="id" />
                      )}
                      &nbsp;
                    </div>
                    <ul
                      className={
                        this.state.langdrop
                          ? "menu-dropdown dropdown-menu show"
                          : "menu-dropdown dropdown-menu"
                      }
                      aria-labelledby="navbarDropdown"
                    >
                      <li className="drop-menu-item">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => this.changelanguage("id")}
                        >
                          <FlagIcon code="id" /> &nbsp; Bahasa
                        </div>
                      </li>
                      <li className="drop-menu-item">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => this.changelanguage("en")}
                        >
                          <FlagIcon code="us" /> &nbsp; English
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="navbar-toggler btn-close-off-canvas"
              type="button"
              onClick={this.menu}
            >
              <GiHamburgerMenu size="30" />
            </button>
          </nav>
        </div>
      </header>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lang: (value) => {
      dispatch(setlanguage(value));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    language: state.setting.language,
  };
};

const Header = connect(mapStateToProps, mapDispatchToProps)(CmpHeader);

export default Header;
