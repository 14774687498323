import React, { Component } from "react";
// import { Link } from "react-router-dom";
import FindBar from "./cmp_find_bar";
class CmpBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  afterThrow = (key) => {
    this.props.findBar(key)
  }

  render() {
    return (
      <>
        <div className="feature-section section-padding">
          <div
            className="shape-1 aos-init aos-animate"
            data-aos="fade-left"
            data-aos-duration="400"
            data-aos-once="true"
          >
            <img src={require("../../image/png/l1-feature-oval.png")} alt="" />
          </div>
          <div className="shape-2">
            <img src={require("../../image/svg/l1-curve-feature.svg")} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8 pl-lg--40 order-lg-12">
                <FindBar
                  style={this.props.style}
                  throwParam={this.afterThrow}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CmpBanner;
