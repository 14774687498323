import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "baseui/skeleton";
import http from "../../config/http";
import styles from "../../style/blog.module.scss";
import { Img } from "react-image";
import { connect } from "react-redux";
class CmpFeatureSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: [],
      sidebar: [],
      query: "",
      total: 0,
      ref: 0,
      page: 1,
      curpage: 1,
      cursize: 10,
      loading: false,
      loading_bar: false,
    };
  }

  componentDidMount() {
    const query = this.getQueryVariable("query");
    this.getBlog(query);
  }

  getBlog = (parameter) => {
    let data = {
      url: "blog_query",
      body: {
        query: true,
        param: parameter,
        curpage: this.state.curpage,
        cursize: this.state.cursize,
        lang: this.props.language,
      },
      token: false,
    };
    this.setState({
      loading: true,
      query: parameter,
    });
    http.APIget(data).then((res) => {
      if (res.results.status) {
        let item = Math.ceil(res.results.total / this.state.cursize);
        this.setState({
          search: res.results.data,
          total: res.results.total,
          page: item,
          loading: false,
        });
        this.getBlogBar();
      } else {
        console.log(res.results.error_message);
      }
    });
  };
  more = () => {
    var item = this.refs.refItem;
    var childitem = this.refs.refItem.children.length;
    this.setState(
      {
        loading: true,
        cursize: this.state.cursize + 10,
      },
      () => {
        let data = {
          url: "blog_query",
          body: {
            query: true,
            param: this.state.query,
            curpage: this.state.curpage,
            cursize: this.state.cursize,
            lang: this.props.language,
          },
          token: false,
        };
        http.APIget(data).then((res) => {
          if (res.results.status) {
            let item = Math.ceil(res.results.total / this.state.cursize);
            this.setState({
              search: res.results.data,
              total: res.results.total,
              page: item,
              loading: false,
              ref: childitem,
            });
          } else {
            console.log(res.results.error_message);
          }
        });
      }
    );
  };
  getBlogBar = () => {
    let data = {
      url: "sidebar_category",
      body: {
        query: true,
        lang: this.props.language,
      },
      token: false,
    };
    this.setState(
      {
        loading_bar: true,
      },
      () => {
        http.APIget(data).then((res) => {
          if (res.results.status) {
            this.setState(
              {
                sidebar: res.results.data.category,
                loading_bar: false,
              },
              () => {}
            );
          } else {
            console.log(res.results.error_message);
          }
        });
      }
    );
  };

  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    console.log(query); //"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars); //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair); //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  };

  errorImage = (event) => {
    console.log(event);
    event.target.src = "https://professionals.tarkett.com/media/img/M/THH_25094225_25187225_001.jpg"
  }

  loaderImage = (key, alt) => {
    return (
      // <Img
      //   src={[key, ""]}
      //   loader={
      //     <Skeleton
      //       rows={1}
      //       width="100%"
      //       animation
      //       overrides={{
      //         Row: {
      //           style: {
      //             height: "240px",
      //             borderRadius: "20px",
      //           },
      //         },
      //       }}
      //     />
      //   }
      //   alt={alt}
      // />
      <img src={key} alt={alt} onError={this.errorImage}/>
    );
  };

  render() {
    return (
      <div>
        <div className="blog-post-area mt-5">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12">
                <div className={styles.catTitle}>
                  <h1>SEARCH RESULTS</h1>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-8">
                <div className={styles.section}>
                  <div className="row">
                    <div className={styles.componentsearch}>
                      <div className={styles.titleheading}>
                        <h1>Search Result of</h1>
                      </div>
                      <p>
                        Display Results for <b>"{this.state.query}"</b>
                      </p>
                    </div>
                  </div>
                </div>
                {this.state.loading === false ? (
                  <div className={styles.section} ref="refItem">
                    {this.state.search.length !== 0 ? (
                      <>
                        {this.state.search.map((k, i) => {
                          if (i % 1 === 0) {
                            return (
                              <Link
                                key={i}
                                className={styles.categorylistInner}
                                to={"/blog/" + k.slug}
                              >
                                <div className={styles.categorylistWrapper}>
                                  <div className={styles.categorylistmedia}>
                                    <div
                                      className={styles.categorylistmediaImg}
                                    >
                                      <img
                                        alt="Genshin Impact Update 1.1, Ini Detail Perbaruannya"
                                        width="300"
                                        height="200"
                                        sizes="(max-width: 300px) 100vw, 300px"
                                        src={k.image}
                                        className="img-fluid"
                                      ></img>
                                    </div>
                                    <div
                                      className={styles.categorylistmediaBody}
                                    >
                                      <span className="captialize">
                                        {k.catalog_name}
                                      </span>
                                      <h2 className="category">
                                        {k.title}
                                      </h2>
                                      <p className="d-none d-md-block">
                                        {k.simple_content}
                                      </p>
                                      <span className="date">
                                        {k.created_date}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          } else {
                            return (
                              <Link
                                key={i}
                                className={styles.categorylistInner}
                                to={"/blog/" + k.slug}
                              >
                                <div className={styles.categorylistWrapper}>
                                  <div className={styles.categorylistmedia}>
                                    <div
                                      className={styles.categorylistmediaImg}
                                    >
                                      <img
                                        alt="Genshin Impact Update 1.1, Ini Detail Perbaruannya"
                                        width="300"
                                        height="200"
                                        sizes="(max-width: 300px) 100vw, 300px"
                                        src={k.image}
                                        className="img-fluid"
                                      ></img>
                                    </div>
                                    <div
                                      className={styles.categorylistmediaBody}
                                    >
                                      <span className="captialize">
                                        {k.catalog_name}
                                      </span>
                                      <h2 className="category">
                                        {k.title}
                                      </h2>
                                      <p className="d-none d-md-block">
                                        {k.simple_content}
                                      </p>
                                      <span className="date">
                                        {k.created_date}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                          }
                        })}
                        {this.state.total >= this.state.ref ? (
                          <div className={styles.categorylistInner}>
                            <div className={styles.categorylistWrapper}>
                              <button
                                onClick={this.more}
                                className={styles.btnmore}
                              >
                                see more
                              </button>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <>
                        <div>
                          <p className={styles.emptyBlog}>
                            Maaf, kami tidak dapat menemukan apa yang Anda cari.
                            Silakan coba ulangi dengan cara:
                          </p>{" "}
                          <ul className={styles.emptyBlogItem}>
                            <li>Memeriksa kesalahan penulisan</li>{" "}
                            <li>Mengganti kata kunci</li>{" "}
                            <li>Menggunakan kata yang lebih umum</li>{" "}
                            <li>Mengurangi jumlah kata kunci</li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={styles.section}>
                    <Link className={styles.categorylistInner} to="/">
                      <div className={styles.categorylistWrapper}>
                        <div className={styles.categorylistmedia}>
                          <div className={styles.categorylistmediaImg}>
                            <Skeleton
                              rows={1}
                              width="100%"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "127px",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className={styles.categorylistmediaBody}>
                            <Skeleton
                              rows={1}
                              width="80px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="120px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "8px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={3}
                              width="400px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="20px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "5px",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link className={styles.categorylistInner} to="/">
                      <div className={styles.categorylistWrapper}>
                        <div className={styles.categorylistmedia}>
                          <div className={styles.categorylistmediaImg}>
                            <Skeleton
                              rows={1}
                              width="100%"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "127px",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className={styles.categorylistmediaBody}>
                            <Skeleton
                              rows={1}
                              width="80px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="120px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "8px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={3}
                              width="400px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="20px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "5px",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link className={styles.categorylistInner} to="/">
                      <div className={styles.categorylistWrapper}>
                        <div className={styles.categorylistmedia}>
                          <div className={styles.categorylistmediaImg}>
                            <Skeleton
                              rows={1}
                              width="100%"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "127px",
                                  },
                                },
                              }}
                            />
                          </div>
                          <div className={styles.categorylistmediaBody}>
                            <Skeleton
                              rows={1}
                              width="80px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="120px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "8px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={3}
                              width="400px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "10px",
                                  },
                                },
                              }}
                            />
                            <Skeleton
                              rows={1}
                              width="20px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "10px",
                                    marginTop: "5px",
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {this.state.loading_bar === false ? (
                  <>
                    {this.state.sidebar.length !== 0 ? (
                      <>
                        {this.state.sidebar.map((k, i) => {
                          return (
                            <div key={i}>
                              {k.blog.length !== 0 ? (
                                <>
                                  <div key={i} className={styles.titleheading}>
                                    <h1>{k.catalog_name}</h1>
                                  </div>
                                  <div className={styles.categorysidebar}>
                                    {k.blog.map((value, key) => {
                                      return (
                                        <Link
                                          key={key}
                                          to={"/blog/" + value.slug}
                                          className={
                                            styles.categorysidebarInner
                                          }
                                        >
                                          <div
                                            className={
                                              styles.categorysidebarWrapper
                                            }
                                          >
                                            <div
                                              className={
                                                styles.categorysidebarMedia
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.categorysidebarMediaImg
                                                }
                                              >
                                                <img
                                                  alt="Tampil Menggila, Siren Esports Angkat Piala MDL Season 2!"
                                                  width="300"
                                                  height="200"
                                                  sizes="(max-width: 300px) 100vw, 300px"
                                                  src={value.image}
                                                ></img>
                                              </div>
                                              <div
                                                className={
                                                  styles.categorysidebarBody
                                                }
                                              >
                                                <h2>{value.title}</h2>
                                                <span className="date">
                                                  {value.created_date}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div>
                          <p className={styles.emptyBlog}>
                            Maaf, kami tidak dapat menemukan apa yang Anda cari.
                            Silakan coba ulangi dengan cara:
                          </p>{" "}
                          <ul className={styles.emptyBlogItem}>
                            <li>Memeriksa kesalahan penulisan</li>{" "}
                            <li>Mengganti kata kunci</li>{" "}
                            <li>Menggunakan kata yang lebih umum</li>{" "}
                            <li>Mengurangi jumlah kata kunci</li>
                          </ul>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className={styles.titleheading}>
                      <h1>
                        <Skeleton
                          rows={1}
                          width="120px"
                          animation
                          overrides={{
                            Row: {
                              style: {
                                height: "20px",
                              },
                            },
                          }}
                        />
                      </h1>
                    </div>
                    <div className={styles.categorysidebar}>
                      <Link to="/" className={styles.categorysidebarInner}>
                        <div className={styles.categorysidebarWrapper}>
                          <div className={styles.categorysidebarMedia}>
                            <div className={styles.categorysidebarMediaImg}>
                              <Skeleton
                                rows={1}
                                width="100px"
                                animation
                                overrides={{
                                  Row: {
                                    style: {
                                      height: "100px",
                                    },
                                  },
                                }}
                              />
                            </div>
                            <div className={styles.categorysidebarBody}>
                              <h2>
                                <Skeleton
                                  rows={2}
                                  width="120px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "10px",
                                        marginBottom: "10px",
                                      },
                                    },
                                  }}
                                />
                              </h2>
                              <span className="date">
                                <Skeleton
                                  rows={1}
                                  width="80px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "13px",
                                      },
                                    },
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="/" className={styles.categorysidebarInner}>
                        <div className={styles.categorysidebarWrapper}>
                          <div className={styles.categorysidebarMedia}>
                            <div className={styles.categorysidebarMediaImg}>
                              <Skeleton
                                rows={1}
                                width="100px"
                                animation
                                overrides={{
                                  Row: {
                                    style: {
                                      height: "100px",
                                    },
                                  },
                                }}
                              />
                            </div>
                            <div className={styles.categorysidebarBody}>
                              <h2>
                                <Skeleton
                                  rows={2}
                                  width="120px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "10px",
                                        marginBottom: "10px",
                                      },
                                    },
                                  }}
                                />
                              </h2>
                              <span className="date">
                                <Skeleton
                                  rows={1}
                                  width="80px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "13px",
                                      },
                                    },
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <Link to="/" className={styles.categorysidebarInner}>
                        <div className={styles.categorysidebarWrapper}>
                          <div className={styles.categorysidebarMedia}>
                            <div className={styles.categorysidebarMediaImg}>
                              <Skeleton
                                rows={1}
                                width="100px"
                                animation
                                overrides={{
                                  Row: {
                                    style: {
                                      height: "100px",
                                    },
                                  },
                                }}
                              />
                            </div>
                            <div className={styles.categorysidebarBody}>
                              <h2>
                                <Skeleton
                                  rows={2}
                                  width="120px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "10px",
                                        marginBottom: "10px",
                                      },
                                    },
                                  }}
                                />
                              </h2>
                              <span className="date">
                                <Skeleton
                                  rows={1}
                                  width="80px"
                                  animation
                                  overrides={{
                                    Row: {
                                      style: {
                                        height: "13px",
                                      },
                                    },
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setting.language,
  };
};

const Body = connect(mapStateToProps)(CmpFeatureSearch);

export default Body;
