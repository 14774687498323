import React, { Component } from "react";
// import http from "../../config/http";
// import { Skeleton } from "baseui/skeleton";
import { throttle } from "throttle-debounce";
import { I18n } from "react-redux-i18n";
import { blogQuery } from "../../redux/action/general";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class CmpBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      key: false,
      query: "",
      view_query: [],
    };
    this.delay_query = throttle(4000, this.keygo);
  }

  componentDidUpdate() {}

  onFocus = () => {
    this.setState({
      key: true,
    });
  };

  onBlur = () => {
    this.setState({
      key: false,
    });
  };

  fillup = (event) => {
    this.setState({ query: event.target.value }, () => {});
  };

  keygo = (param) => {
    this.props.query(param);
  };

  forwardParam = (key) => {
    this.props.history.push("blog/" + key);
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.props.history.push("blog/search?query=" + this.state.query);
    }
  };

  render() {
    return (
      <div className={this.props.style.keyItem_container}>
        <div className={this.props.style.keyItem_global}>
          <div
            className={
              this.state.key
                ? this.props.style.containerkeyItemHover
                : this.props.style.containerkeyItem
            }
          >
            <div className={this.props.style.keyFlex}>
              <div className={this.props.style.keyIcon}>
                <div className={this.props.style.icon}>
                  <img
                    className={this.props.style.iconItem}
                    alt=""
                    src={require("../../image/png/search.png")}
                  ></img>
                </div>
              </div>
              <input
                onChange={this.fillup}
                onKeyDown={this._handleKeyDown}
                className={this.props.style.keyItem}
                name="param"
                type="text"
                placeholder={I18n.t("feature.keyword")}
              />
              <div
                className={
                  this.state.key
                    ? this.props.style.keyIcon
                    : this.props.style.keyIconHide
                }
              >
                <div
                  className={this.props.style.icon}
                  onClick={() => this.onBlur()}
                >
                  <img
                    className={this.props.style.cleariconItem}
                    src={require("../../image/png/close.png")}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.key
                ? this.props.style.containerkeyDropdown
                : this.props.style.containerkeyDropHide
            }
          >
            <div className={this.props.style.dropdown}>
              <div className={this.props.style.dropdownDivider}></div>
              <ul className={this.props.style.dropdownItem}>
                {this.props.general.length !== 0 ? (
                  this.props.general.map((k, i) => {
                    return (
                      <li
                        key={i}
                        className={this.props.style.itemBar}
                        onClick={() => this.forwardParam(k.slug)}
                      >
                        <div className={this.props.style.containerItem}>
                          <div className={this.props.style.itemIcon}></div>
                          <div className={this.props.style.optionItem}>
                            <div className={this.props.style.optionData}>
                              <span>{k.title}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className={this.props.style.itemBar}>
                    <div className={this.props.style.containerItem}>
                      <div className={this.props.style.itemIcon}></div>
                      <div className={this.props.style.optionItem}>
                        <div className={this.props.style.optionData}>
                          <span>{I18n.t("feature.keywordMissing")}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    query: (k) => {
      dispatch(blogQuery(k));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    general: state.general.blog,
  };
};

const BlogBar = connect(mapStateToProps, mapDispatchToProps)(withRouter(CmpBar));

export default BlogBar;
