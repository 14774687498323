import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from "react";
import { Img } from "react-image";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import  "../../style/custom-carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { rupiah_to_integer, rupiah, rupiah_with_symbolic } from '../../config/format.rupiah';
import { Skeleton } from "baseui/skeleton";
import { Tabs, Tab } from "baseui/tabs-motion";
import OpenApp from "react-open-app";

export default function ProductDetail({styles, props, data}) {

    const [activeKey, setActiveKey] = React.useState("0");

    console.log(data);

    const onChange = () => {

    }
    
    const onClickItem = () => {
        
    }
    
    const onClickThumb = () => {
        
    }

    const ErrorImage = (event) => {
        console.log(event);
        event.target.src = "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"
    }

    const WrapperImage = ({key, alt}) => {
        return (
            <Img
                src={[key, "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"]}
                loader={
                    <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                            Row: {
                                style: {
                                    height: "240px",
                                    borderRadius: "10px",
                                },
                            },
                        }}
                    />
                }
                onError={ErrorImage}
                alt={alt}
            />
        );
    };

    const android_open = () => {
        const url = "intent://ikanesia.com/#Intent;scheme=https;package=com.ikanesia.app.dev;end";
        window.location.replace(url);
    }

    return(
        <React.Fragment>
            {
                data !== null ? (<div className="container mt-5 mb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}>
                                {data.media.map(function (object, i) {
                                    return (
                                        <div key={i}>
                                            <img src={object.media_path} onError={ErrorImage} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                        <div className="col-md-5">
                            <div className={styles.productdetailcolumn}>
                                <h1 className={styles.productdetailname}>
                                    {data.product_name}
                                </h1>
                                <div className={styles.productdetailsellinfo}>
                                    <p className={styles.productsellinginfo}><span class="main">Terjual</span> {data.sold_item}</p>
                                    <span aria-hidden="true">•</span>
                                    <p className={styles.productsellinginfo}><span class="main">Diskusi</span> {data.discussions_total}</p>
                                
                                </div>
                                <div className={styles.price}>
                                    Rp {rupiah(data.product_price)}
                                </div>
                                <div className={styles.productdivider}></div>
                                <p className={styles.choosevariantitle}>Pilih varian: <span>{data.product_name}</span></p>
                                <div className={styles.productvariantwrapper}>
                                    {
                                        data.variant.length !== 0 ? (
                                            <React.Fragment>
                                                {data.variant.map(function (object, i) {
                                                    return (
                                                        <div onClick={() => android_open()} key={i} className={styles.variant_chip}>
                                                            <img className={styles.variant_photo} src={object.product_thumbnail} onError={ErrorImage} />
                                                            {object.subname}
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>
                                        ) : null
                                    }
                                </div>
                                <div className={styles.productdivider}></div>
                            </div>
                            <div className={styles.productdetailcolumn}>
                                <Tabs
                                    activeKey={activeKey}
                                    onChange={({ activeKey }) => {
                                        setActiveKey(activeKey);
                                    }}
                                    activateOnFocus
                                >
                                    <Tab title="Detail">
                                        <div dangerouslySetInnerHTML={{
                                            __html: data.product_description.replace(/\n/g, "<br />"),
                                              }}></div>
                                    </Tab>
                                    <Tab title="Info lainnya">
                                        <div dangerouslySetInnerHTML={{
                                            __html: data.other_description.replace(/\n/g, "<br />"),
                                        }}></div>
                                    </Tab>
                                    <Tab title="T&C">
                                        <div dangerouslySetInnerHTML={{
                                            __html: data.terms_description.replace(/\n/g, "<br />"),
                                        }}></div>
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className={styles.productdetailcolumn}>
                                <div className={styles.productdivider}></div>
                                <div className={styles.productshopinfo}>
                                    <div className={styles.productshopphotoswrapper}>
                                        <img className={styles.productshopphotos} src={data.store_logo} onError={ErrorImage} />
                                    </div>
                                    <div className={styles.productshopnamewrapper}>
                                        <div className={styles.productshopname}>
                                            <h2 className={styles.productshoptitle}>
                                                {data.store_name}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.productdivider}></div>
                            </div>
                            
                        
                        </div>
                        <div className="col-md-3 d-none-md">
                            <div className={styles.productcart}>
                                <h6 className={styles.productcarttitle}>Atur jumlah dan catatan</h6>
                                <button type="button" className={styles.productcartbutton} aria-live="assertive" aria-atomic="true">
                                    <img src={data.product_thumbnail} onError={ErrorImage} />
                                    <p className="css-14deqii-unf-heading e1qvo2ff8">
                                        {data.product_name}
                                    </p>
                                </button>
                                <div className={styles.productcartbuttonquantity}>
                                    <div className={styles.productcartbuttonquantityeditor}>
                                        <button onClick={() => android_open()} aria-label="Kurangi 1" className={styles.productcartbuttonminus} disabled="" tabindex="-1">
                                            <svg class="unf-icon" viewBox="0 0 24 24" width="16px" height="16px" fill="#4761a9" style={{ display: 'inline-block', verticalAlign: 'middle'}}><path d="M20 12.75H4a.75.75 0 110-1.5h16a.75.75 0 110 1.5z"></path></svg>
                                        </button>
                                        <input aria-valuenow="1" aria-valuemin="1" aria-valuemax="72" className={styles.productcartbuttonquantityeditorinput} role="spinbutton" type="text" value="1" />
                                        <button onClick={() => android_open()} aria-label="Tambah 1" className={styles.productcartbuttonminus} tabindex="-1">
                                            <svg class="unf-icon" viewBox="0 0 24 24" width="16px" height="16px" fill="#4761a9" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                                <path d="M20 11.25h-7.25V4a.75.75 0 10-1.5 0v7.25H4a.75.75 0 100 1.5h7.25V20a.75.75 0 101.5 0v-7.25H20a.75.75 0 100-1.5z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <label className={styles.productcartjumlah} for="qty-editor-atc">jumlah</label>
                                    <p className={styles.productcartstok}>Stok: <b>{data.product_quantity}</b></p>
                                </div>
                                <div className={styles.productcartbuttoncart}>
                                    <button onClick={() => android_open()} className={styles.productcartbuttoncreate}>
                                        <span>+ Beli</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : null
            }
        </React.Fragment>
    )
}