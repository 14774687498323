export const settheme = (theme) => {
  return {
    type: "SET_THEME",
    payload: theme,
  };
};

export const setlanguage = (lang) => {
  return {
    type: "SET_LANGUAGE",
    payload: lang,
  };
};

export function clearstate() {
  return {
    type: "RESET_STATE",
  };
}
