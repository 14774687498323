export const setstorage = (name, data) => {
  let source = JSON.stringify(data);
  return localStorage.setItem(name, source);
};

export const getstorage = (name) => {
  let source = localStorage.getItem(name);
  if (source) {
    return JSON.parse(source);
  } else {
    return {};
  }
};

export const removestorage = (name) => {
  return localStorage.removeItem(name);
};
