import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
// import { Link } from "react-router-dom";

class CmpFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="feature-section section-padding">
          <div
            className="shape-1 aos-init aos-animate"
            data-aos="fade-left"
            data-aos-duration="400"
            data-aos-once="true"
          >
            <img src={require("../../image/png/l1-feature-oval.png")} alt="" />
          </div>
          <div className="shape-2">
            <img src={require("../../image/svg/l1-curve-feature.svg")} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 pl-lg--40 order-lg-2">
                <div className="feature-content section-title">
                  <h2 className="title">{I18n.t("home.featurePreTitle")}</h2>
                  <p className="">{I18n.t("home.featurePreInner")}</p>
                  {/* <div className="content-btn">
                    <Link to="/" className="btn--secondary hvr-bounce-to-left">
                      Check all features
                    </Link>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="feature-card-wrapper">
                  <div className="row">
                    <div className="col-md-6 mt--30">
                      <div className="feature-card">
                        <div className="card-icon red">
                          <img
                            className="img-icon"
                            src={require("../../image/png/fish-bag.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-text">
                          <h3 className="title">
                            {I18n.t("home.featureTitle1")}
                          </h3>
                          <p>{I18n.t("home.featureInner1")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt--30">
                      <div className="feature-card">
                        <div className="card-icon yellow">
                          <img
                            className="img-icon"
                            src={require("../../image/png/law.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-text">
                          <h3 className="title">
                            {" "}
                            {I18n.t("home.featureTitle2")}
                          </h3>
                          <p>{I18n.t("home.featureInner2")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt--30">
                      <div className="feature-card">
                        <div className="card-icon blue">
                          <img
                            className="img-store-icon"
                            src={require("../../image/png/—Pngtree—store icon_4419850.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-text">
                          <h3 className="title">Premium Seller</h3>
                          <p>{I18n.t("home.featureInner3")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt--30">
                      <div className="feature-card">
                        <div className="card-icon ash">
                          <img
                            className="img-icon"
                            src={require("../../image/png/payment.png")}
                            alt=""
                          />
                        </div>
                        <div className="card-text">
                          <h3 className="title">
                            {I18n.t("home.featureTitle4")}
                          </h3>
                          <p>{I18n.t("home.featureInner4")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section1 section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-2 col-md-9 col-sm-10">
                <div className="content-img-group content-img-group-1 position-relative ">
                  <div
                    className="img-1"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-once="true"
                  >
                    <img
                      style={{ borderRadius: 20 }}
                      src={require("../../image/png/phone-illu-2.png")}
                      alt=""
                    />
                  </div>
                  <div
                    className="abs-img img-2"
                    data-aos="fade-right"
                    data-aos-duration="400"
                    data-aos-once="true"
                    data-aos-delay="300"
                  >
                    <div className="image-card-2 card-yellow">
                      <div className="card-icon">
                        <i className="fas fa-bell"></i>
                      </div>
                      <div className="card-text">
                        <span className="date">Ikanesia store</span>
                        <p>{I18n.t("home.featurePreIllustration1")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 order-lg-1"
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <div className="content-text section-title pr--25">
                  <h2 className="title">Lifetime Chat</h2>
                  <p>{I18n.t("home.featurePreInner1")}</p>
                  {/* <div className="content-btn">
                    <Link to="/" className="btn--primary hvr-bounce-to-left">
                      Daftar toko
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-section2 section-padding">
          <div className="shape-1">
            <img
              src={require("../../image/svg/l1-curve-content2.svg")}
              alt=""
            />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-9 col-sm-10 mb--30 mb-lg--0">
                <div className="content-img-group content-img-group-2 position-relative">
                  <div
                    className="img-1"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-once="true"
                  >
                    <img
                      style={{ borderRadius: 20 }}
                      src={require("../../image/png/phone-illu-3.png")}
                      alt=""
                    />
                  </div>
                  <div
                    className="abs-img img-2"
                    data-aos="fade-right"
                    data-aos-duration="400"
                    data-aos-once="true"
                    data-aos-delay="300"
                  >
                    <div className="image-card-2 card-red">
                      <div className="card-icon">
                        <i className="fas fa-bell"></i>
                      </div>
                      <div className="card-text">
                        <span className="date">
                          {I18n.t("home.featurePreInnerTitle1")}
                        </span>
                        <p>{I18n.t("home.featurePreIllustration2")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-7"
                data-aos="fade-left"
                data-aos-duration="500"
                data-aos-once="true"
              >
                <div className="content-text pl-lg--50">
                  <div className="section-title">
                    <h2 className="title">
                      It’s everything you’ll{" "}
                      <br className="d-none d-sm-block" /> ever need.
                    </h2>
                    <p className="pr-lg--45">
                      {I18n.t("home.featurePreInner2")}
                    </p>
                  </div>
                  <div className="widgets-wrapper mt--35">
                    <div className="content-widget mb--15">
                      <div className="widget-text">
                        <h3 className="title">
                          {I18n.t("home.featurePreInnerTitle2")}
                        </h3>
                        <span className="sub-title">
                          {I18n.t("home.featurePreInnerItem2")}
                        </span>
                      </div>
                    </div>
                    <div className="content-widget mb--15">
                      <div className="widget-text">
                        <h3 className="title">
                          {I18n.t("home.featurePreInnerTitle3")}
                        </h3>
                        <span className="sub-title">
                          {I18n.t("home.featurePreInnerItem3")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-section section-padding">
          <div
            className="shape-1"
            data-aos="fade-right"
            data-aos-duration="400"
            data-aos-once="true"
          >
            <img src={require("../../image/png/l1-cta-oval.png")} alt="" />
          </div>
          <div className="shape-2">
            <img src={require("../../image/svg/l1-curve-cta.svg")} alt="" />
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 col-xl-6">
                <div className="section-title text-center">
                  <h2 className="title">{I18n.t("home.footerTitle")}</h2>
                  <p>{I18n.t("home.footerInner")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CmpFeature;
