import React, { Component } from "react";
import http from "../../config/http"
import { I18n } from "react-redux-i18n";
// import { Skeleton } from "baseui/skeleton";
import { throttle } from "throttle-debounce";
class CmpFindBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      key: false,
      query: "",
      view_query: [],
    };
    this.delay_query = throttle(2000, this.keygo);
  }

  onFocus = () => {
    this.setState({
      key: true,
    });
  };

  onBlur = () => {
    this.setState({
      key: false,
    });
  };

  onKey = (event) => {
    this.setState({ query: event.target.value }, () => {
      this.delay_query(this.state.query);
    });
  };

  keygo = (param) => {
    let data = {
      url: "faq",
      body: {
        query: true,
        param: param,
      },
      token: false,
    };
    http.APIget(data).then((res) => {
      if (res.results.status) {
        this.setState({
          view_query: res.results.query,
        });
      } else {
        console.log(res.results.error_message);
      }
    });
  };

  forwardParam = (key) => {
    this.setState({
      key: false,
    });
    this.props.throwParam(key);
  }

  render() {
    const view_query = this.state.view_query || [];
    return (
      <div className={this.props.style.keyItem_container}>
        <div className="text-center title-faq">Kami siap membantu anda</div>
        <div className={this.props.style.keyItem_global}>
          <div
            className={
              this.state.key
                ? this.props.style.containerkeyItemHover
                : this.props.style.containerkeyItem
            }
          >
            <div className={this.props.style.keyFlex}>
              <div className={this.props.style.keyIcon}>
                <div className={this.props.style.icon}>
                  <img
                    className={this.props.style.iconItem}
                    alt=""
                    src={require("../../image/png/search.png")}
                  ></img>
                </div>
              </div>
              <input
                onFocus={this.onFocus}
                onChange={this.onKey}
                className={this.props.style.keyItem}
                name="param"
                type="text"
                placeholder={I18n.t("feature.keywordFaq")}
              />
              <div
                className={
                  this.state.key
                    ? this.props.style.keyIcon
                    : this.props.style.keyIconHide
                }
              >
                <div
                  className={this.props.style.icon}
                  onClick={() => this.onBlur()}
                >
                  <img
                    className={this.props.style.cleariconItem}
                    src={require("../../image/png/close.png")}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.key
                ? this.props.style.containerkeyDropdown
                : this.props.style.containerkeyDropHide
            }
          >
            <div className={this.props.style.dropdown}>
              <div className={this.props.style.dropdownDivider}></div>
              <ul className={this.props.style.dropdownItem}>
                {view_query.length !== 0 ? (
                  view_query.map((k, i) => {
                    return (
                      <li
                        key={i}
                        className={this.props.style.itemBar}
                        onClick={() => this.forwardParam(k.id)}
                      >
                        <div className={this.props.style.containerItem}>
                          <div className={this.props.style.itemIcon}></div>
                          <div className={this.props.style.optionItem}>
                            <div className={this.props.style.optionData}>
                              <span>{k.label}</span>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li className={this.props.style.itemBar}>
                    <div className={this.props.style.containerItem}>
                      <div className={this.props.style.itemIcon}></div>
                      <div className={this.props.style.optionItem}>
                        <div className={this.props.style.optionData}>
                          <span>{I18n.t("feature.keywordMissing")}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CmpFindBar;
