/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import general from "./general";
import setting from "./setting";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
// Wrap the root reducer and return a new root reducer with router state
export default combineReducers({
  setting,
  general,
  i18n: i18nReducer,
});
