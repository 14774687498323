import React, { Component } from "react";

import { Link } from "react-router-dom";

class CmpFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="footer-section">
          <div className="container">
            <div className="footer-top">
              <div className="row justify-content-center">
                <div className="col-lg-2 col-md-4">
                  <div className="footer-logo mb--50">
                    <img
                      className="circle-logo"
                      src={require("../../../image/png/logo.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-10 col-md-8">
                  <div className="row">
                    <div className="col-6 col-lg-6">
                      <div className="single-footer mb--50 mb-lg--30">
                        <h5 className="footer-title">LINK BANTUAN</h5>
                        <ul className="footer-list">
                          <li>
                            <Link to="/faq">F.A.Q</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6">
                      <div className="single-footer mb--50 mb-lg--30">
                        <h5 className="footer-title">PANDUAN & BANTUAN</h5>
                        <ul className="footer-list">
                          <li>
                            <Link to="/terms-condition">Terms & condition</Link>
                          </li>
                          <li>
                            <Link to="/privacy-policy">Privacy policy</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright-area">
              <div className="row align-items-center">
                <div className="col-sm-6  text-sm-left text-center mb-2 mb-sm-0">
                  <p className="copyright-text">
                    &copy; 2020 Ikanesia, All Rights Reserved
                  </p>
                </div>
                <div className="col-sm-6 text-sm-right text-center">
                  <ul className="social-icons">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/ikanesiadotcom"
                      >
                        <i className="icon icon-logo-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/ikanesiadotcom/"
                      >
                        <i className="icon icon-logo-fb-simple"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CmpFooter;
