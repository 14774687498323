import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageHome from "../page/page-home";
import PageTerms from "../page/page-terms-condition";
import PagePrivacy from "../page/page-privacy-policy";
import PageFaq from "../page/page-faq";
import PageBlog from "../page/page-blog";
import PageBlogReview from "../page/page-blog-review";
import PageBlogSearch from "../page/page-blog-search";
import PageBlogDetail from "../page/page-blog-detail";
import PageLoaderShareURL from "../page/page-loader-share-url";
import PageProductDetailURLShare from "../page/page-product-detail-share-url";

class route extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/faq" exact>
            <PageFaq />
          </Route>
          <Route path="/privacy-policy" exact>
            <PagePrivacy />
          </Route>
          <Route path="/blog/:year/:month/:slug" exact>
            <PageBlogDetail />
          </Route>
          <Route path="/blog/review/:slug" exact>
            <PageBlogReview />
          </Route>
          <Route path="/blog/search" exact>
            <PageBlogSearch />
          </Route>
          <Route path="/blog" exact>
            <PageBlog />
          </Route>
          <Route path="/terms-condition" exact>
            <PageTerms />
          </Route>
          <Route path="/" exact>
            <PageHome />
          </Route>
          <Route path="/:code" exact>
            <PageLoaderShareURL />
          </Route>
          <Route path="/:store/:category/:product" exact>
            <PageProductDetailURLShare />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default route;