import promiseMiddleware from "redux-promise-middleware";
import logger from "redux-logger";
const middleware = [];

// middleware redux promise
middleware.push(promiseMiddleware);

// middlewares logger
if (process.env.NODE_ENV !== "production") {
  middleware.push(logger);
} 
export default middleware;
