import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "baseui/skeleton";
import http from "../../config/http";
import styles from "../../style/blog.module.scss";
import { Img } from "react-image";
import { VscFoldDown } from "react-icons/vsc";
import { connect } from "react-redux";

class CmpFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presrelease: [],
      release: [],
      category: [],
      total: 0,
      loading: false,
      page: 1,
      curpage: 1,
      cursize: 12,
    };
  }

  componentDidMount() {
    this.getBlog();
  }

  getBlog = () => {
    let data = {
      url: "blog",
      body: {
        query: true,
        curpage: this.state.curpage,
        cursize: this.state.cursize,
        lang: this.props.language,
      },
      token: false,
    };
    this.setState({
      loading: true,
    });
    http.APIget(data).then((res) => {
      if (res.results.status) {
        let item = Math.ceil(res.results.total / this.state.cursize);
        this.setState({
          presrelease: res.results.data.presrelease,
          release: res.results.data.release,
          category: res.results.data.category,
          total: res.results.total,
          page: item,
          loading: false,
        });
      } else {
        console.log(res.results.error_message);
      }
    });
  };

  errorImage = (event) => {
    console.log(event);
    event.target.src = "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"
  }


  loaderImage = (key, alt) => {
    return (
      <Img
        src={[key, "https://user-images.githubusercontent.com/47315479/81145216-7fbd8700-8f7e-11ea-9d49-bd5fb4a888f1.png"]}
        loader={
          <Skeleton
            rows={1}
            width="100%"
            animation
            overrides={{
              Row: {
                style: {
                  height: "240px",
                  borderRadius: "20px",
                },
              },
            }}
          />
        }
        onError={this.errorImage}
        alt={alt}
      />
    );
  };

  render() {
    var innerloading = [];
    for (var i = 0; i < 6; i++) {
      innerloading.push(
        <div className="col-lg col-sm categoryItem thumb">
          <div className="media">
            <div className="media-img">
              <Skeleton
                rows={1}
                width="100%"
                animation
                overrides={{
                  Row: {
                    style: {
                      height: "140px",
                    },
                  },
                }}
              />
            </div>
            <div className="media-body">
              <span className="category mt-2">
                <Skeleton
                  rows={1}
                  width="40px"
                  animation
                  overrides={{
                    Row: {
                      style: {
                        height: "13px",
                      },
                    },
                  }}
                />
              </span>
              <h2>
                <Skeleton
                  rows={1}
                  width="120px"
                  animation
                  overrides={{
                    Row: {
                      style: {
                        height: "13px",
                      },
                    },
                  }}
                />
              </h2>
              <p>
                <Skeleton
                  rows={1}
                  width="180px"
                  animation
                  overrides={{
                    Row: {
                      style: {
                        height: "13px",
                      },
                    },
                  }}
                />
              </p>
              <span className="date">
                <Skeleton
                  rows={1}
                  width="40px"
                  animation
                  overrides={{
                    Row: {
                      style: {
                        height: "13px",
                      },
                    },
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="blog-post-area mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--50">
                {this.state.loading === false ? (
                  <div className="row">
                    {this.state.release.map((k, i) => {
                      if (i % 2 === 0) {
                        return (
                          <div key={i} className="col-sm-6 p-0">
                            <div className={styles.innerItem}>
                              <Link
                                to={"blog/" + k.slug}
                                className={styles.inner}
                              >
                                <div className={styles.media}>
                                  <div className={styles.mediaImg}>
                                    {this.loaderImage(k.image, k.title)}
                                    <div className={styles.overlayInner}></div>
                                  </div>
                                  <div className={styles.mediaBody}>
                                    <span>{k.catalog_name}</span>
                                    <h2>{k.title}</h2>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-sm-6 col-md-3 p-0">
                            <div className={styles.innerItem}>
                              <Link to={"blog/" + k.slug}>
                                <div className={styles.media}>
                                  <div className={styles.mediaImg}>
                                    {this.loaderImage(k.image, k.title)}
                                    <div className={styles.overlayInner}></div>
                                  </div>
                                  <div className={styles.mediaBody}>
                                    <span>{k.catalog_name}</span>
                                    <h2>{k.title}</h2>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="col-lg-3 col-md-3 col-12 p-0">
                      <div className="row m-0">
                        {this.state.presrelease.map((z, p) => {
                          return (
                            <div
                              key={p}
                              className="col-lg-12 col-md-12 col-6 p-0"
                            >
                              <div className={styles.thumbInner}>
                                <Link to={"blog/" + z.slug}>
                                  <div className={styles.media}>
                                    <div className={styles.mediaImg}>
                                      {this.loaderImage(z.image, z.title)}
                                      <div
                                        className={styles.overlayInner}
                                      ></div>
                                    </div>
                                    <div className={styles.mediaBody}>
                                      <span>{z.catalog_name}</span>
                                      <h2>{z.title}</h2>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-sm-6 p-2">
                      <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "400px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-sm-6 col-md-3 p-2">
                      <Skeleton
                        rows={1}
                        width="100%"
                        animation
                        overrides={{
                          Row: {
                            style: {
                              height: "400px",
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-12 p-0">
                      <div className="row m-0">
                        <div className="col-lg-12 col-md-12 col-6 mt-2 mb-1">
                          <Skeleton
                            rows={1}
                            width="100%"
                            animation
                            overrides={{
                              Row: {
                                style: {
                                  height: "196px",
                                },
                              },
                            }}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-6 mt-1">
                          <Skeleton
                            rows={1}
                            width="100%"
                            animation
                            overrides={{
                              Row: {
                                style: {
                                  height: "196px",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.loading === false ? (
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <div className="mt-5 mb-5">
                        <div className={styles.categoryinnerwrapper}></div>
                        <div className={styles.categorynavwrapper}>
                          <div className={styles.categorynavigation}>
                            {this.state.category.map((z, p) => {
                              if (z.blog.length !== 0) {
                                return (
                                  <div className={styles.categorynavItem}>
                                    <Link to={"/blog/review/" + z.slug}>
                                      {z.catalog_name}
                                    </Link>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.category.map((z, p) => {
                      if (z.blog.length !== 0) {
                        return (
                          <div className="component-1 p-lg-0 p-sm-0 py-0">
                            <div className="title-heading">
                              <h1>
                                <Link
                                  to={"/blog/review/" + z.slug}
                                  className=""
                                >
                                  {z.catalog_name}
                                </Link>
                              </h1>
                            </div>
                            <div className="section-component">
                              <div className="row p-2">
                                {z.blog.map((i, k) => {
                                  return (
                                    <div className="col-lg col-sm categoryItem thumb">
                                      <Link to={"/blog/" + i.slug}>
                                        <div className="media">
                                          <div className="media-img">
                                            {this.loaderImage(i.image, i.title)}
                                          </div>
                                          <div className="media-body">
                                            <span className="category">
                                              {i.catalog_name}
                                            </span>
                                            <h2>{i.title}</h2>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: i.simple_content,
                                              }}
                                            ></p>
                                            <span className="date">
                                              {i.created_date}
                                            </span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="link-kepo d-none d-sm-block">
                                <Link
                                  to={"/blog/review/" + z.slug}
                                  className=""
                                >
                                  See More <VscFoldDown className="ml-2" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div className="row mt-5 mb-5 p-1">
                    <div className="component-1 p-lg-0 p-sm-0 py-0">
                      <div className="title-heading">
                        <h1>
                          <a href="/fresh" className="">
                            <Skeleton
                              rows={1}
                              width="120px"
                              animation
                              overrides={{
                                Row: {
                                  style: {
                                    height: "20px",
                                  },
                                },
                              }}
                            />
                          </a>
                        </h1>
                      </div>
                      <div className="section-component">
                        <div className="row p-2">{innerloading}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setting.language,
  };
};

const Body = connect(mapStateToProps)(CmpFeature);

export default Body;
