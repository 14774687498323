const en = {
  home: {
    title: "Home",
    bannerTitle: "Marketplace Buying and Selling Ornamental Fish",
    subBannerTitle:
      "Ikanesia marketplace provides you to buy and sell fish pet globally",
    bannerInner1: "Congratulations ! You have won the auction",
    bannerInner2: "Good day ! Your fish will arrived immediately",
    featureTitle1: "Buy & sell",
    featureTitle2: "Auction",
    featureTitle3: "Premium Seller",
    featureTitle4: "Trusted Payments",
    featureInner1:
      "Helps you to explore various fish pet and other pet catalogs.",
    featureInner2:
      "Join your auction to win many exotic fish from thousands of seller.",
    featureInner3:
      "Maximize your seller account and get many tools with premium seller.",
    featureInner4: "Free your worries with the payment methods in Ikanesia.",
    featurePreTitle: "Let's start selling at Ikanesia.",
    featurePreInner:
      "Ikanesia is here to meet the needs of fish lovers in buying and selling transactions and also auctioning ornamental fish. In addition, we also provide a place to sell various accessories and needs of various kinds of ornamental fish.",
    featurePreInner1:
      "Get in contact with buyer and seller easily with private chat and discuss chat feature.",
    featurePreIllustration1: "Hello, are there any ornamental fish?",
    featurePreIllustration2: "We provide various kinds of catalogs for you.",
    featurePreInnerTitle1: "Wide Variety of Catalogs",
    featurePreInnerTitle2: "Complete Category",
    featurePreInnerItem2:
      "Ikanesia category will always be updated and follow market developments and demands.",
    featurePreInnerItem3:
      "You will have your own account balance. You can withdraw funds at any time you want.",
    featurePreInnerTitle3: "Account Balance",
    footerTitle: "Why in Ikanesia",
    footerInner:
      "Exploring and finding fish is fun and joyful. Ikanesia provides fish auctions event that you will regret to miss it. Don’t worry! we will make sure either buyers and sellers security during transaction.",
    featurePreInner2:
      "Users can become sellers and buyers at the same time without having to create two different accounts. Everything has been provided by Ikanesia and you can use it for free.",
  },
  feature: {
    keyword: "Search here",
    keywordFaq: "What do you want to ask?",
    keywordMissing: "The word you were looking for was not found ....",
  },
  blog: {
    commentTitle: "Beri komentar kamu",
    commentName: "Your name",
    commentEmail: "Your email address",
    commentMessage: "Write your comment . . . .",
    commentSubmit: "Send",
    commentHeader: "List comment for this article",
    commentTerm: "Comment successfully on posting",
  },
};

export default en;
