import React, { Component } from "react";
import http from "../../config/http";
import { Skeleton } from "baseui/skeleton";
import { connect } from "react-redux";
class CmpFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      privacy: {},
    };
  }

  componentDidMount() {
    this.getPrivacyData();
  }

  getPrivacyData = () => {
    let data = {
      url: "useFullContent",
      body: {
        query: true,
        language: this.props.language,
        type: 3,
      },
      token: false,
    };
    http.APIget(data).then((res) => {
      if (res.results.status) {
        this.setState({
          loading: false,
          privacy: res.results.data,
        });
      } else {
      }
    });
  };

  render() {
    return (
      <>
        <main className="terms-page-main pt--120 pb--30 pt-md--110 pb-md--105">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 col-xl-8">
                <div className="terms-contents">
                  <h1 className="title-main text-center mb--30 mb-lg--55">
                    {this.state.loading === true ? (
                      <Skeleton
                        rows={1}
                        animation
                        width="320px"
                        overrides={{
                          Root: {
                            style: ({ $theme }) => {
                              return {
                                margin: "0 auto",
                              };
                            },
                          },
                          Row: {
                            style: {
                              height: "20px",
                              borderRadius: "10px",
                              marginBottom: "15px",
                              textAlign: "center",
                            },
                          },
                        }}
                      />
                    ) : (
                      this.state.privacy.title
                    )}
                  </h1>
                  {this.state.loading === true ? (
                    <>
                      <p>
                        <Skeleton
                          rows={1}
                          animation
                          width="100%"
                          overrides={{
                            Root: {
                              style: ({ $theme }) => {
                                return {
                                  margin: "0 auto",
                                };
                              },
                            },
                            Row: {
                              style: {
                                height: "20px",
                                borderRadius: "10px",
                                marginBottom: "15px",
                                textAlign: "center",
                              },
                            },
                          }}
                        />
                      </p>
                      <div className="article-block mt--40 mt-md--50">
                        <h2 className="block-title mb--30">
                          <Skeleton
                            rows={1}
                            animation
                            width="200px"
                            overrides={{
                              Row: {
                                style: {
                                  height: "20px",
                                  borderRadius: "10px",
                                  marginBottom: "15px",
                                  textAlign: "center",
                                },
                              },
                            }}
                          />
                        </h2>
                        <p>
                          <Skeleton
                            rows={2}
                            animation
                            width="100%"
                            overrides={{
                              Root: {
                                style: ({ $theme }) => {
                                  return {
                                    margin: "0 auto",
                                  };
                                },
                              },
                              Row: {
                                style: {
                                  height: "20px",
                                  borderRadius: "10px",
                                  marginBottom: "15px",
                                  textAlign: "center",
                                },
                              },
                            }}
                          />
                        </p>
                      </div>
                      <div className="article-block mt--40 mt-md--50">
                        <h2 className="block-title  mb--30">
                          <Skeleton
                            rows={1}
                            animation
                            width="280px"
                            overrides={{
                              Row: {
                                style: {
                                  height: "20px",
                                  borderRadius: "10px",
                                  marginBottom: "15px",
                                  textAlign: "center",
                                },
                              },
                            }}
                          />
                        </h2>
                        <Skeleton
                          rows={3}
                          animation
                          width="100%"
                          overrides={{
                            Root: {
                              style: ({ $theme }) => {
                                return {
                                  margin: "0 auto",
                                };
                              },
                            },
                            Row: {
                              style: {
                                height: "20px",
                                borderRadius: "10px",
                                marginBottom: "15px",
                                textAlign: "center",
                              },
                            },
                          }}
                        />
                      </div>
                      <div className="article-block mt--40 mt-md--50">
                        <h2 className="block-title  mb--30">
                          <Skeleton
                            rows={1}
                            animation
                            width="280px"
                            overrides={{
                              Row: {
                                style: {
                                  height: "20px",
                                  borderRadius: "10px",
                                  marginBottom: "15px",
                                  textAlign: "center",
                                },
                              },
                            }}
                          />
                        </h2>
                        <Skeleton
                          rows={3}
                          animation
                          width="100%"
                          overrides={{
                            Root: {
                              style: ({ $theme }) => {
                                return {
                                  margin: "0 auto",
                                };
                              },
                            },
                            Row: {
                              style: {
                                height: "20px",
                                borderRadius: "10px",
                                marginBottom: "15px",
                                textAlign: "center",
                              },
                            },
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.privacy.content,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setting.language,
  };
};

const PrivacyPolicy = connect(mapStateToProps)(CmpFeature);

export default PrivacyPolicy;
